import { Injectable } from '@angular/core';
import { AlertController, ModalController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

	private loader: any;
	private alert: any;
	private modal: any;

	constructor(
		private loadingCtrl: LoadingController,
		private alertCtrl: AlertController,
		private modalCtrl: ModalController
	) { }

	async presentLoadingMask(message: string)
	{
		this.loader = await this.loadingCtrl.create({
			message: `${message}...`
		});

		await this.loader.present();

		return this.loader;
	}

	async dismissLoadingMask()
	{
		await this.loader.dismiss();
	}

	async presentAlert(title: string, message: string, buttons: any, inputs: any)
	{
		this.alert = await this.alertCtrl.create({
			header: title,
			message: message,
			buttons: buttons,
			inputs: inputs,
			backdropDismiss: false
		});

		await this.alert.present();
	}

	async dismissAlert()
	{
		await this.alert.dismiss();
	}

	async presentModal(page: any, properties: any)
	{
		this.modal = await this.modalCtrl.create({
			component: page,
			componentProps: properties
		});

		await this.modal.present();
	}

	async dismissModal()
	{
		await this.modal.dismiss();
	}
}
