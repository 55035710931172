import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class GlobalVariablesService {

	private api: string = 'http://api.pixeltechlabs.com/rgfood.php';

	constructor() {}

	public getApi()
	{
		return this.api;
	}
}
