import { Component, OnInit, Input } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Component({
	selector: 'app-employee',
	templateUrl: './employee.page.html',
	styleUrls: ['./employee.page.scss']
})
export class EmployeePage implements OnInit {

	@Input() employee;
	@Input() employees;

	constructor() {}

	ngOnInit() {}

	async addUpdateEmployee()
	{
		this.employees.utils.presentLoadingMask(this.employee.employee_id > 0 ? 'Updating employee...' : 'Adding employee...').then(async () => {

			let valid = await this.validate();

			if (valid.valid) {

				await this.employees.http.post(
					this.employees.globalVars.getApi(),
					{},
					{ 
						headers: new HttpHeaders({"Content-Type": "text/plain"}),
						params: {
							action: this.employee.employee_id > 0 ? 'updateEmployee' : 'addEmployee',
							employee: JSON.stringify(this.employee)
						}
					}
				)
				.subscribe(async (data: any) => {

					if (data.success) {

						await this.employees.utils.dismissModal();
						await this.employees.utils.dismissLoadingMask();
						await this.employees.refresh();
						await this.employees.utils.presentAlert(
							'Success',
							this.employee.employee_id > 0 ? 'Employee updated.' : 'Employee added.',
							[
								{
									text: 'OK',
									handler: () => {
										
										this.employees.utils.dismissAlert();
									}
								}
							],
							[]
						);
					}
					else {

						await this.employees.utils.dismissLoadingMask();
						await this.employees.utils.presentAlert(
							'Error',
							data.message,
							[
								{
									text: 'OK',
									handler: () => {
										
										this.employees.utils.dismissAlert();
									}
								}
							],
							[]
						);
					}
				});
			}
			else {

				await this.employees.utils.dismissLoadingMask();
				await this.employees.utils.presentAlert(
					'Error',
					valid.message,
					[
						{
							text: 'OK',
							handler: () => {
								
								this.employees.utils.dismissAlert();
							}
						}
					],
					[]
				);
			}
		});
	}

	async deleteEmployee()
	{
		if (this.employee.employee_id > 0) {

			await this.employees.utils.presentAlert(
				'Alert',
				`Are you sure you want to delete this employee?`,
				[
					{
						text: 'YES',
						handler: async () => {

							await this.employees.utils.presentLoadingMask('Deleting employee...').then(async () => {

								await this.employees.http.post(
									this.employees.globalVars.getApi(),
									{},
									{ 
										headers: new HttpHeaders({"Content-Type": "text/plain"}),
										params: {
											action: 'deleteEmployee',
											employee_id: this.employee.employee_id
										}
									}
								)
								.subscribe(async (data: any) => {

									if (data.success) {

										await this.employees.utils.dismissModal();
										await this.employees.utils.dismissLoadingMask();
										await this.employees.refresh();
										await this.employees.utils.presentAlert(
											'Alert',
											'Employee Deleted',
											[
												{
													text: 'OK',
													handler: () => {
														
														this.employees.utils.dismissAlert();
													}
												}
											],
											[]
										);
									}
									else {

										await this.employees.utils.dismissLoadingMask();
										await this.employees.utils.presentAlert(
											'Error',
											data.message,
											[
												{
													text: 'OK',
													handler: () => {
														
														this.employees.utils.dismissAlert();
													}
												}
											],
											[]
										);
									}
								});
							});
						}
					},
					{
						text: 'NO',
						handler: () => {
							
							this.employees.utils.dismissAlert();
						}
					}
				],
				[]
			);
		}
	}

	async validate()
	{
		let res = {
			valid: true,
			message: ''
		};

		if (this.employee.name.trim() == '') {

			res.valid = false;
			res.message = 'Name is required.';
		}

		return res;
	}

	cancel()
	{
		this.employees.utils.dismissModal();
	}
}