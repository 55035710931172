import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ToolsService {

	constructor() { }

	getFormattedDate(date: any)
	{
		let res = '';

		let day = `${date.getDate()}`;

		if (day.length == 1) {

			day = `0${day}`;
		}

		let month = `${date.getMonth() + 1}`;

		if (month.length == 1) {

			month = `0${month}`;
		}

		res = `${date.getFullYear()}-${month}-${day}`;

		return res;
	}
}
